export const Equipment =() => [
    {
      name: 'client_cust_id',
      col: 8,
      lable: 'Customer Name',
      type: 'dropdown',
      rules: [],
    },
    {
        name: 'ticket_ref_id',
        col: 8,
        disabled: true,
        lable: 'Ticket No.',
        rules: [],
      },
      {
        name: 'equipment_no',
        lable: 'Equipment No.',
        col: 8,
        type: 'autocomplete',
        value: [],
        rules: [
          {
            required: true,
            message: 'Please Select equipment number!',
            whitespace: true,
          },
        ],
      },
      {
        name:'custom1'
      },

     {
      name: 'equipment_dropoff_date',
      lable: 'Equipment Drop Off Date',
      type:'dateTime',
      col: 8,
    },
    {
        name: 'ticket_wash_start_time',
        lable: 'Equipment Wash Start Date',
        type:'dateTime',
        col: 8,
      },
      {
        name: 'ticket_wash_end_time',
        lable: 'Equipment Wash End Date',
        type:'dateTime',
        col: 8,
      },
          {
        name: 'time_taken',
        lable: 'Time Taken',
        type:'timeTaken',
        col: 8,
        rules: [],
      },
    
      {
        name: 'last_known',
        lable: 'Hazardous',
        type: 'radio',
        col: 8,
        value: [
          { value: 'nonhaz', displayValue: 'No' },
          { value: 'haz', displayValue: 'Yes' },
        ],
        rules: [
          {
            required: true,
            message: 'Please Select Hazardous Category!',
            whitespace: true,
          },
        ],
      },
      {
        name: 'hazardous',
        lable: 'Last Contained',
        disabled: false,
        col: 8,
        rules: [
          {
            message: 'Please Enter Last Contained!',
            whitespace: true,
          },
        ],
      },
      {
        name: 'generator',
        lable: 'From/Generator',
        col: 8,
        rules: [
          {
            required: true,
            message: 'Please Enter form generator!',
            whitespace: true,
          },
        ],
      },
      {
        name:'services',
        disabled: true,
        lable:'Services',
        value:[],
        type:'multiselect',
        col:8
      },
       {
      name: 'location_id',
      col: 8,
      lable: 'Yard Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select yard name!',
          whitespace: true,
        },
      ],
    },
        {
      name: 'certificate_no',
      lable: 'Washout Certificate Number',
      col: 8,
    },
  
   
  ];

  export const confirmation =() => [
    {
      name: 'email',
      lable: 'PROVIDE DROP OFF CONFIRMATION',
      placeholder: 'EMAIL',
    },
    // {
    //   name: 'number',
    //   lable: 'OR',
    //   placeholder: 'PHONE',
    // },
  ];
  export const FoodTruck =() => [
    {
      name: 'client_cust_id',
      col: 8,
      lable: 'Customer Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Customer!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'ticket_ref_id',
      col: 8,
      disabled: true,
      lable: 'Ticket No.',
      rules: [],
    },
    {
      name: 'equipment_no',
      lable: 'Trailer Unit No.',
      type: 'autocomplete',
      value: [],
      col: 8,
      rules: [
        {
          required: true,
          message: 'Please Enter trailer unit number!',
          whitespace: true,
        },
      ],
    },
    {
      name: 'truck_number',
      lable: 'Truck No.',
      col: 8,
    },
    {
      name: 'equipment_dropoff_date',
      lable: 'Equipment Drop Off Date',
      type:'dateTime',
      col: 8,
    },
    {
        name: 'ticket_wash_start_time',
        lable: 'Equipment Wash Start Date',
        type:'dateTime',
        col: 8,
      },
      {
        name: 'ticket_wash_end_time',
        lable: 'Equipment Wash End Date',
        type:'dateTime',
        col: 8,
      },
          {
        name: 'time_taken',
        lable: 'Time Taken',
        type:'timeTaken',
        col: 8,
        rules: [],
      },
    // {
    //   name: 'wash_type_requested',
    //   lable: 'WASH TYPE REQUESTED',
    //   type: 'dropdown',
    //   value: [],
    //   col: 8,
    //   rules: [{
    //     required: true,
    //     message: 'Please Select Wash Type Request!',
    //   },]
    // },
    {
      name: 'kosher_certified',
      lable: 'Kosher Certified',
      type: 'radio',
      col: 8,
      // disabled:true,
      value: [
        { value:false, displayValue: 'No' },
        { value:true, displayValue: 'Yes' },
      ],
      rules: []
    },
    {
      name: 'last_known',
      lable: 'Last Contained #1',
      col: 8,
    },
    {
      name: 'last_known2',
      lable: 'Last Contained #2',
      col: 8,
    },
    {
      name: 'last_known3',
      lable: 'Last Contained #3',
      col: 8,
    },
    {
      name:'services',
      disabled: true,
      lable:'Services',
      value:[],
      type:'multiselect',
      col:8
    },
    {
      name: 'location_id',
      lable: 'Yard Name',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Yard Name!',
          whitespace: true,
        },
      ],
      col: 8,
    },
    {
      name: 'certificate_no',
      lable: 'Washout Certificate Number',
      col: 8,
    },
  ];

  export const PrewashFormFood=()=>[
 {
      name: 'owner',
      lable: 'Owner',
      col: 8,
    },
    {
        name: 'driver_name',
        lable: 'Driver Name',
        col: 8,
      },
     
      {
        name: 'pump_cleaning',
        lable: 'Pump Cleaning',
        type: 'radio',
        col: 8,
        value: [
          { value:false, displayValue: 'No' },
          { value:true, displayValue: 'Yes' },
        ],
        rules: []
      },
      {
        name: 'hose_cleaning',
        lable: 'Hose Cleaning',
        type: 'radio',
        col: 8,
        value: [
          { value:false, displayValue: 'No' },
          { value:true, displayValue: 'Yes' },
        ],
        rules: []
      },
      {
        name: 'waiting_with_trailer',
        lable: 'Waiting With Trailer',
        type: 'radio',
        col: 8,
        value: [
          { value:false, displayValue: 'No' },
          { value:true, displayValue: 'Yes' },
        ],
        rules: []
      },
      {
        name: 'po_id',
        lable: 'PO No.',
        type: 'dropdown',
        value: [],
        col: 8,
        rules: [
          {
            required: false,
            message: 'Please Enter po!',
            whitespace: true,
          },
        ],
      },
     
    {
      name: 'remark',
      type: 'textArea',
      lable: 'Deatils for Bulk Items/Remarks',
      col: 8,
      rules: [],
    },
    {
        name: 'pre_note',
        type: 'textArea',
        lable: 'Pre Wash Note',
        col: 8,
        rules: [],
      },
  ]

  export const PrewashForm=()=>[
    {
         name: 'owner',
         lable: 'Owner',
         col: 8,
       },
       {
           name: 'driver_name',
           lable: 'Driver Name',
           col: 8,
         },
         {
           name: 'trucking_carrier',
           lable: 'Truck Carrier',
           col: 8,
         },
         
       {
           name: 'job_site',
           lable: 'Job Site',
           col: 8,
         },
         {
           name: 'manifest',
           lable: 'Manifest No.',
           col: 8,
           rules: [
             {
               message: 'Please Enter manifest!',
               whitespace: true,
             },
           ],
         },
         {
           name: 'po_id',
           lable: 'PO No.',
           type: 'dropdown',
           value: [],
           col: 8,
           rules: [
             {
               required: false,
               message: 'Please Enter po!',
               whitespace: true,
             },
           ],
         },
       {
         name: 'remark',
         type: 'textArea',
         lable: 'Deatils for Bulk Items/Remarks:',
         col: 8,
         rules: [],
       },
       {
           name: 'pre_note',
           type: 'textArea',
           lable: 'Pre Wash Note',
           col: 8,
           rules: [],
         },
     ]
   

  export const postWashForm=()=>[
      {
        name: 'waste_type',
        type: 'radio',
        value: [
          { value: 'SOLID', displayValue: 'SOLID' },
          { value: 'LIQUID', displayValue: 'LIQUID' },
        ],
        lable: 'Waste Type',
        col: 8,
        rules: [],
      },
      {
        name: 'ticket_complete_remark',
        type: 'textArea',
        lable: 'Note',
        col: 8,
        rules: [],
      },
  ]


  export const estimate =() =>( {
    name: 'estimate',
    col: 24,
    lable: 'ESTIMATE',
    rules: [
      {
        required: true,
        message: 'Please Enter estimate!',
      },
      { type: 'number' },
      {
        pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
        message: 'Estimate value should be greater than 0 number',
      },
    ],
  })
  
import React, { Component, useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Radio,
  DatePicker,
  Space,
  Select,
  Row,
  Collapse,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Footer from '../../components/layout-components/modal/Footer';
import API from '../../api';
import {Equipment,PrewashForm,postWashForm,confirmation,estimate, PrewashFormFood} from './editTicketform'
import { assendingSort,IndustryType,TextCapitalize,TimeDifference } from '../../utils/helper';
import { connect } from 'react-redux';
import { fetchYardList } from '../../actions/yardLocationAction';
import { CustomerList } from '../../actions/customerAction';
import { getAssetList, getInYardList,getTicketDetails } from '../../actions/myYardAction';
import {getWashStatus,getDashboardCount,getTicketAwaiting} from '../../actions/dashboardAction'
import _, { isArray } from 'lodash';
// import PopOver from './PopOver';
import moment from 'moment';
import { usePosition } from '../../utils/usePosition';
// import ImageCompression from '../../utils/imageCompress';
import imageCompression from 'browser-image-compression';
import {FetchParamValue} from '../../utils/helper';

const { Option } = Select;
const { Panel } = Collapse;

const EditTicket = (props) => {
  const {visible,setVisible,equipment,setEquipment}=props;
  const token =
    typeof window !== 'undefined' ? localStorage.getItem('token') : '';
  const [form] = Form.useForm();
  const [prewashForm,setPrewashForm]=useState(IndustryType()!=="Food_Grade" ?PrewashForm():PrewashFormFood())
  const [btnDisable, setBtnDisable] = useState(false);
  const [rejectPop, setRejectPop] = useState(false);
  const [clientDetail, setClientDetail] = useState(null);
  const {
    imageUpdate,
    imageDelete,
    getUserDetails,
    poNumber,
    assetSearch,
    washTypeRequest,
    equipmentCheckin,
    getEquipmentData,
    ticketDetailsUpdate,
    approveEquipmentData,
    rejectEquipment,
    mailWithOutAttachment,
    updateCertificateNumber
  } = API;
  const { customerList, yardLocationData } = props;
  const [apiData,setApiData]=useState(null)
  const [remark, setRemark] = useState('');
  const [assetId, setAssetId] = useState('');
  const [oldEquipmentNo,setOldEquipmentNo]=useState({text:null,assetId:null})
  const [loading, setLoading] = useState(true);
  const { latitude, longitude } = usePosition();
  const [equipmentNo,setEquipmentNumber]=useState([])
  const [eqCategory,setEqCategory]=useState(null)
  const [eqTypeData,setEqTypeData]=useState([]);
  const [eqType,setEqType]=useState(null);
  const [eqNumber,setEqNumber]=useState(null);
  const [allEqNumber,setAllEqNumber]=useState(null);
  const [dropOff, setDropOff] = useState(moment());
  const [washStart, setWashStart] = useState(moment());
  const [washEnd, setWashEnd] = useState(moment());
  const [timeDiff,setTimeDiff]=useState(moment())
  const [washDocs,setWashDocs]=useState([])
  const onSearch = (text, value) => {
    eqDropdownUpdate('equipment_no', eqNumber);
    const _value = eqNumber && eqNumber.filter((item) => item.value === text);
    if (_value&&_value.length === 0) {
      if(oldEquipmentNo.text===text){
        setAssetId(oldEquipmentNo.assetId);
      }else{
        setAssetId(null);
      }
      
      disableItems('equipment_type', false, false, true);
      // form.setFieldsValue({ equipment_type: '' });
    } else {
      if(oldEquipmentNo.text!==text){
        form.setFieldsValue({ equipment_type: _value[0].eqNo });
        onSerchEquipmentType(_value[0].eqNo)
      }
      disableItems('equipment_type', true, false, true);
      disableItems('equipment_no', false, true, true);
      setAssetId(_value[0].asset_id);
    }
  };

  const onSelect = (data, value) => {
    value &&
      value.map((item) => {
        if (item.value === data) {
          if(oldEquipmentNo.text!==data){
            form.setFieldsValue({ equipment_type: item.eqNo });
            onSerchEquipmentType(item.eqNo)
          }
          setAssetId(item.asset_id);
          disableItems('equipment_type', true, false, true);
          disableItems('equipment_no', false, true, true);
        }
      });
  };
  const onSerchEquipmentType=(eqNo)=>{
    eqTypeData.map((type)=>{
      if(type.value===eqNo){
        form.setFieldsValue({ equipment_category: type.parent_id.toString() });    
        dropDownEqCategory(type.parent_id,'new')    
      }
    })
}

  const disableItems = (name, value, require, requireVal) => {
    const eq = [...equipment];
    eq.map((item) => {
      if (item.name === name) {
        item.disabled = value;
        if (require) {
          item.rules.map((rules) => {
            rules.required = requireVal;
          });
        }
      }
    });
    setEquipment(eq);
  };

  useEffect(
    () => {
      getPoListFunc();
    },
    [props.user_id, props.title]
  );
  const getPoListFunc = () => {
    if (props.title === 'Edit User' && props.user_id !== undefined) {
      getUserDetails(props.user_id).then((res) => {
        const _data = {
          ...res.data.data,
          role_id: res.data.data.role_id.toString(),
        };
        form.setFieldsValue(_data);
      });
    }
  };

  useEffect(
    () => {
      form.resetFields();
      const callback = (val) => {};
      if (visible === true) {
        if (!props.asset_txns_id) {
          // setFileList([]);
        }
        Equipment(props?.editData?.ticket_status_value!==3220)
        getEqDetails();
        const tabParam = JSON.parse(localStorage.getItem('data'));

        setClientDetail({
          clientName: tabParam.first_name,
          clientCompany: tabParam.clientCompany,
          email: tabParam.email,
          phone: tabParam.mobile,
        });
        props.CustomerList(callback);
      } else {
        // setFileList(null);
        setLoading(true);
      }
    },
    [visible]
  );
  const EquipmentCategory=()=>{
    const tabParam = JSON.parse(localStorage.getItem('data'));
    const _eqCategory = [];
    const _eqType=[]
    tabParam.TabParams &&
      tabParam.TabParams.map((data) => {
        if (data.param_key === 'equipment_category') {
          _eqCategory.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
          });
        }else if(data.param_key==="equipment_type"){
          _eqType.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
            parent_id:data.parent_id.toString()
          })
        }
      });
      setEqTypeData(assendingSort(_eqType))
      setEqCategory(assendingSort(_eqCategory))
  }


  const getEqDetails = () => {
    if (props.editData) {
      getPoList(props.editData?.client_cust_id);
      washTypeRequest().then((res)=>{
        const _data=res.data.data;
        const washReq = []
        _data &&
        _data.map((data) => {
          washReq.push({
              value: data.id.toString(),
              displayValue: data['service_name.param_description']+" "+(data.category?`(${TextCapitalize(data.category)})`:""),
              category:data.category
            });
          });
      eqDropdownUpdate('wash_type_requested',assendingSort(washReq));
    })
        setLoading(false);
        let _data =props.editData; 
    const obj= Object.keys(props.editData).filter(function(key, index) {
    return  Array.isArray(props.editData[key])===true
        });
        let arrayData={...props.editData}
        const eqNo=[]
        dropDownSelect(arrayData.client_cust_id, 'client_cust_id',eqNo)
        const arr = arrayData.sds.concat(arrayData.prewash, arrayData.postwash);
        const imgArr=[]
        arr.map((item)=>{
          imgArr.push({ "id":item.doc_id,
          "doc_path":item.url,
          "doc_name":item.name,
          "remark":"test note",
          "ticket_id":parseInt(props.routeId)})
        })
        setWashDocs(imgArr)
        const eqTypeParentId=arrayData?.equipment_type_id?.parent_id
        dropDownEqCategory(eqTypeParentId&&eqTypeParentId.toString(),'new')
        
        obj.map((item)=>{          
          delete arrayData[item]
        })
      let formData={...arrayData,
        pre_note:arrayData.note,
        remark:arrayData.remarks,
      equipment_type:arrayData?.equipment_type_id?.param_value?.toString(),
      last_known:arrayData.haz==="No"?'nonhaz':'haz',
      hazardous:arrayData.last_known,
      equipment_wash_time:'',
      equipment_dropoff_date:'', 
      equipment_category: arrayData?.equipment_type_id?.parent_id?.toString(),}
      if(IndustryType()==="Food_Grade"){
        formData={...formData,last_known:arrayData.last_known,
          pump_cleaning:arrayData.pump_cleaning==="Yes"?true:false,
          hose_cleaning:arrayData.hose_cleaning==="Yes"?true:false,
          kosher_certified:arrayData.kosher_certified==="Yes"?true:false,
          waiting_with_trailer:arrayData.waiting_with_trailer==="Yes"?true:false,
          wash_type_requested:arrayData.wash_type_requested_code,
          last_known2:arrayData.last_known_2,
          last_known3:arrayData.last_known_3,
        
        }
      }
      setApiData(formData)
      form.setFieldsValue(formData);
    const eq= [...equipment]
    eq.map((item,i)=>{
              if(item.name==="ticket_wash_start_time"){
                 setWashStart(parseInt(arrayData.ticket_wash_start_time)||moment())
            }else if(item.name==="ticket_wash_end_time"){
              setWashEnd(parseInt(arrayData.ticket_wash_end_time)||moment())
            }
            else if(item.name==="equipment_dropoff_date"){
              setDropOff(arrayData.created_at||moment())
            }else if(item.name==="services"){
              let services=[]
              props.editData.services.length!==0&& props.editData.services.map((service)=>{
                services.push(FetchParamValue(service?.service_id))
              })
              form.setFieldsValue({services:services.length!==0?services:['No Services']});
            }
          })
          setEquipment(eq)
          setOldEquipmentNo({...oldEquipmentNo,text:formData?.equipment_no,assetId:formData?.asset_id})
        setAssetId(formData?.asset_id);
        // disableItems('client_cust_id', true, false, true);
        disableItems('ticket_number', true, false, true);
      
    }
  };


  useEffect(
    () => {
      const customer = customerList;
      const clientCustomer = [];
      customer &&
        customer.map((data) => {
          clientCustomer.push({
            value: data.clients_cust_id.toString(),
            displayValue: data.name,
          });
        });
      eqDropdownUpdate('client_cust_id',assendingSort(clientCustomer));
      EquipmentCategory()
    },
    [props.customerList]
  );
  const eqDropdownUpdate = (name, value,data) => {
    const eq = [...equipment];
    let val = value
    if(name ==='equipment_type'){
      assendingSort(val)
      // val.sort((a,b) => (a.displayValue > b.displayValue) ? 1 : ((b.displayValue> a.displayValue) ? -1 : 0))
    }
    eq.map((item) => {
      if (item.name === name) {
        item.value = val;
      }
    });
  
    setEquipment(eq);
  };
  useEffect(
    () => {
      const yardArr = [];
      yardLocationData &&
        yardLocationData.map((data) => {
          yardArr.push({
            value: data.id.toString(),
            displayValue: TextCapitalize(data.name),
            coordinates: data.coordinates,
          });
        });
      eqDropdownUpdate('location_id',assendingSort(yardArr));
    },
    [yardLocationData]
  );

  const dropDownSelect = (e, value,dropValue) => {
    if (value === 'client_cust_id') {
      form.setFieldsValue({
        equipment_no: '',
        equipment_type: '',
        po_id: '',
        location_id: '',
      });
      disableItems('equipment_type', false, false, true);
      assetSearch(e).then((res) => {
        const data = res.data.data;
        const eqNo = dropValue;
        data &&
          data.map((_data) => {
            eqNo.push({
              value: _data.equipment_no.toString(),
              eqNo: `${_data['equipment_type_details.param_value']}` ,
              asset_id: _data.asset_id,
            });
          });
          eqNo&&eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
          setAllEqNumber(eqNo)
          setEqNumber(eqNo)
          const _eqType=  [...eqTypeData]
          _eqType.map((_item)=>{
              _item.eqNo=[]
             const arr=[]
            eqNo.map((eq)=>{
              if(eq.eqNo===_item.value){
                arr.push(eq)
              }
              _item.eqNo=arr
            })
            }) 
            setEqTypeData(_eqType)
            setTimeout(()=>{
              eqDropdownUpdate('equipment_no', eqNo);
            },300)
   
      });
      // getPoList(e);
    } else if (value === 'equipment_type') {
      dropValue.map((item)=>{
        if(item.value===e){
          item.eqNo&& item.eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
          eqDropdownUpdate('equipment_no', item.eqNo);
        }
      })
      if (e === '1130') {
        disableItems('equipment_no', false, true, false);
      } else {
        disableItems('equipment_no', false, true, true);
      }
    }
    else if(value === 'wash_type_requested') {
      const filter =dropValue.filter((item)=>item.value===e)
      form.setFieldsValue({kosher_certified:filter[0]?.category==="kosher certified"?true:false})
    }
  };
  const getPoList = (e) => {
    poNumber(e).then((res) => {
      const _data = res.data.data;
      const po = [];
      _data &&
        _data.map((data) => {
          po.push({
            value: data.po_id.toString(),
            displayValue: data.po_no.toUpperCase(),
          });
        });
        const prewash=[...prewashForm]
        prewash.map((item) => {
          if (item.name === 'po_id') {
            item.value = assendingSort(po);
          }
        });
      
        setPrewashForm(prewash);
    });
  };

  const handleSource = (e) => {
    disableItems('hazardous', false, true, true);
  };
  const dropDownEqCategory=(e,type)=>{
    if(type==='update'){
      form.setFieldsValue({
        equipment_type:''
      });
    }
    if(e==='1080'){
      disableItems('quantity', false, false, true);
      disableItems('equipment_no', false, true, false);
    }else{
      disableItems('quantity', true, false, true);
      disableItems('equipment_no', false, true, true);
    }
    
    const tabParam = JSON.parse(localStorage.getItem('data'));
    const _eqType = [];
    tabParam.TabParams &&
      tabParam.TabParams.map((data) => {
        if (data.param_key === 'equipment_type'&&e===data.parent_id.toString()) {
          _eqType.push({
            value: data.param_value.toString(),
            displayValue: data.param_name,
            parent_id:data.parent_id.toString()
          });
        }
      });
      setEqType(assendingSort(_eqType))
  }
  const dropDownEqType=(e)=>{
    const eqNo=form.getFieldValue('equipment_no')
   const filterEqNo= eqNumber.filter((item)=>eqNo===item.value)
   if(filterEqNo.length!==0){
    form.setFieldsValue({
      equipment_no: '',
    });
   }
      eqTypeData.map((item)=>{
          if(item.value===e){
      item.eqNo&& item.eqNo.sort((a,b) => (a.value.toUpperCase() > b.value.toUpperCase()) ? 1 : ((b.value.toUpperCase()> a.value.toUpperCase()) ? -1 : 0))
      eqDropdownUpdate('equipment_no', item.eqNo);
    }
      })
  }
  const timestamp=(value)=>{
    var dateString = moment(value).format('DD/MM/yyyy HH:mm:ss'),
    dateTimeParts = dateString.split(' '),
    timeParts = dateTimeParts[1].split(':'),
    dateParts = dateTimeParts[0].split('/'),
    date;
    date = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1]);
return date.getTime()
  }
useEffect(()=>{
  setTimeDiff(TimeDifference(washStart,washEnd))

},[washStart,washEnd])
  const onFinish = (values) => {
    // values={...values,wash_time:}
    setBtnDisable(true);
    let coordinates = [];
    const location = form.getFieldValue('location_id');
    const last_known = form.getFieldValue('last_known');
    if(IndustryType()!=="Food_Grade"){
    if (last_known === 'haz') {
      values = { ...values, last_known: 'haz-' + values.hazardous };
    } else {
      values = { ...values, last_known: 'nonhaz-' + values.hazardous };
    }}
    let yardDisplay;
    equipment.map((item) => {
      if (item.name === 'location_id') {
        item.value.map((cord) => {
          if (cord.value === location) {
            yardDisplay = cord.displayValue;
            coordinates = cord.coordinates;
          }
        });
      }
    });
let details={ticket_details:[],txns_details:[]}
if(props.editData.ticket_status_value===3220){
  details={...details,ticket_details:[ 
    {
      "id":props.editData.ticket_wash_start_time_id,
      "field_name":"ticket_wash_start_time",
      "field_value":timestamp(washStart)
  },{
    "id":props.editData.ticket_wash_end_time_id,
    "field_name":"ticket_wash_end_time",
    "field_value":timestamp(washEnd)
  },]}
}
const dynamicUpdate=(params,name,key,value)=>{
  params.map((item)=>{
    if(props.editData[item.id]){
      details[name].push({
        "id":props.editData[item.id],
        [key]:item.name,
        [value]:values[item.name]?values[item.name]:''
    })
    values = {
      ...values,
    [name]:details[name]}
    }else if(!props.editData[item.id]&&values[item.name]){
      details[name].push({
        [key]:item.name,
        [value]:values[item.name]?values[item.name]:''
    })
    values = {
      ...values,
    [name]:details[name]}
    }
  })
}
const txnsDetails=[{id:'trucking_carrier_id',name:'trucking_carrier'},
{id:'manifest_id',name:'manifest'},
{id:'generator_id',name:'generator'}]
const ticketDetails=[{id:'waste_type_id',name:'waste_type'},
{id:'ticket_complete_remark_id',name:'ticket_complete_remark'}]
dynamicUpdate(ticketDetails,'ticket_details','field_name','field_value')
dynamicUpdate(txnsDetails,'txns_details','key','value')

const createdAt=moment.utc(moment(dropOff).toISOString())

  values = {
      ...values,
      coordinates:[0.00, 0.00],
      ticket_remark:values.pre_note,
      created_at:createdAt ,
      ticket_id:props.routeId,
      po_id: values.po_id ? parseInt(values.po_id) : '',
      location_id: parseInt(values.location_id),
      client_cust_id: parseInt(values.client_cust_id),
      washout_docs:washDocs
    };
    if (assetId) {
      values = { ...values, asset_id: assetId,asset_type: values.equipment_type, };
      delete values.equipment_no;
    }else{
      values = { ...values, asset_type: values.equipment_type,
                    equipment_no:values.equipment_no };
    }
    delete values.equipment_type;
      const deleteObj=['file','ticket_complete_remark','waste_type',
      'trucking_carrier','generator','manifest','services',
      'equipment_category','ticket_wash_end_time','ticket_wash_start_time',]
      deleteObj.map((item)=>{
        delete values[item];
      })
      const val = { ...values, asset_txns_id: props.editData.asset_txns_id };
            ticketDetailsUpdate(val)
              .then((response) => {
                if (response.data.success) {
                  
                  const onSuccessFunc=()=>{
                    form.resetFields();
                    setBtnDisable(false);
                    props.getAssetList(0,'');
                    props.getInYardList(0,'');
                    setTimeout(()=>{
                      props.getTicketDetails(props.routeId)
                    },1000)
                 
                    setVisible(false);
                    props.getWashStatus();
                    props.getDashboardCount();
                    props.getTicketAwaiting();
                    message.success('Approved');
                  }
                  if(val.certificate_no){
                    if(val.certificate_no!==apiData.certificate_no){
                  const body = {
                    certificateNumber:val.certificate_no
                  }
                  updateCertificateNumber(props.routeId, body).then((res)=>{
                      if(res.data.success){
                        onSuccessFunc()
                      }else{
                        setBtnDisable(false);
                        message.error(res.data.message);
                      }
                  }).catch((err)=>{
                    setBtnDisable(false);
                    message.error({ content: `${err?.response?.data.message}`, duration: 4 });
                  })
                }
                else{
                  onSuccessFunc()
                }
                  }else{
                    onSuccessFunc()
                  }


                } else {
                  setBtnDisable(false);
                  message.error(response.data.message);
                }
              })
              .catch((err) => {
                setBtnDisable(false);
                message.error({ content: `${err?.response?.data.message}`, duration: 2 });
                // message.error(err);
              });
    
  };

  const disableInput = () => {
    const customerId = form.getFieldValue('client_cust_id');
    if (props.status === 'Rejected') {
      return true;
    } else if (!customerId) {
      return true;
    } else {
      return false;
    }
  };
  const confirmReject = () => {
    const data = { asset_txns_id: props.asset_txns_id, remark: remark };
    rejectEquipment(data).then((res) => {
      setRejectPop(false);
      setRemark('');
      setVisible(false);
    });
  };

  const popCancel = () => {};
  return (
    <Footer
      setVisible={(e) => setVisible(e)}
      visible={visible}
      Button={props.Button}
      custom={props.custom}
      title={
        props.title +
        `${props.status === 'Rejected' ? ' (Ticket Rejected)' : ''}`
      }
      buttonColor={props.buttonColor}
      submit={
        <div>
          {
            // <PopOver
            //   remark={remark}
            //   rejectPop={rejectPop}
            //   setRemark={(e) => setRemark(e.target.value)}
            //   confirmReject={() => confirmReject()}
            //   setRejectPop={(e) => setRejectPop(e)}
            // />
          }
          <Form.Item>
            {props.asset_txns_id && (
              <Button
                disabled={btnDisable || disableInput()}
                type="default"
                onClick={() => setRejectPop(true)}
              >
                Reject Equipment
              </Button>
            )}
            <Button
              disabled={btnDisable || disableInput()}
              type="primary"
              form={
                props.asset_txns_id ? (
                  `approve${props.asset_txns_id.toString()}`
                ) : (
                  'edit'
                )
              }
              htmlType="submit"
            >
              {props.asset_txns_id ? 'Release To Yard' : 'Submit'}
            </Button>
          </Form.Item>
        </div>
      }
    >
  {<Form
        form={form}
        id={
          props.asset_txns_id ? (
            `approve${props.asset_txns_id.toString()}`
          ) : (
            'edit'
          )
        }
        name="editTicket"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={24}>
          {equipment &&
            equipment.map((_form) =>(
              _form.name==="custom1"?<>
                  <Col span={8}>
                    <Form.Item
                        //  getValueFromEvent={_form.type === 'upload' ? normFile : ''}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="equipment_category"
                      rules={[
                            {
                              required: true,
                              message: 'Please Select equipment category!',
                              whitespace: true,
                            },
                          ]}
                      label={<span>Equipment Type</span>}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                          disabled={disableInput()}
                        virtual={false}
                          allowClear={false}
                          placeholder={`CATEGORY`}
                          onChange={(e) => dropDownEqCategory(e,'update')}
                        >
                         {eqCategory&&eqCategory.map((item)=>(
                          <Option value={item.value}>
                                {item.displayValue}
                              </Option>
                         ))
                              
                            }
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={8}>
      
    
                                  <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name="equipment_type"
                      rules={ [
                            {
                              required: true,
                              message: 'Please Select equipment type!',
                              whitespace: true,
                            },
                          ]}
                      label={<span>Equipment Sub Type</span>}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                          disabled={disableInput()}
                        virtual={false}
                          allowClear={false}
                          placeholder={`SUB CATEGORY`}
                          onChange={(e) => dropDownEqType(e)}
                        >
                          <Option value="" disabled>
                          SUB CATEGORY
                          </Option>
                         
                             {eqType&&eqType.map((item)=> <Option value={item.value}>
                                {item.displayValue}
                              </Option>)}
                            
                        </Select>
                    </Form.Item>
                    </Col>
        
        </>      
             :
         <Col span={_form.col}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={_form.name}
                  rules={_form.rules}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  // initialValue={_form.initialValue?_form.initialValue:''}
                >
                  { (_form.type === 'dropdown'||_form.type === 'multiselect') ? (
                    <Select
                    virtual={false}
                    mode={_form.type==="multiselect"&&"multiple"}
                      disabled={
                        (_form.name !== 'client_cust_id' && disableInput()) ||
                        _form.disabled
                      }
                     
                      allowClear={false}
                      placeholder={`SELECT ${_form.lable}`}
                      style={_form.style}
                      onChange={(e) => dropDownSelect(e, _form.name,_form.value)}
                    >
                      {_form.value &&
                        _form.value.map((item) => (
                          <Option value={item.value}>
                            {item.displayValue}
                          </Option>
                        ))}
                    </Select>
                  ) : _form.type === 'radio' ? (
                    <Radio.Group
                      disabled={disableInput()||_form.disabled}
                      onChange={(e) => handleSource(e.target.value)}
                    >
                      {_form.value.map((item) => (
                        <Radio value={item.value}>{item.displayValue}</Radio>
                      ))}
                    </Radio.Group>
                  ) : _form.type === 'textArea' ? (
                    <Input.TextArea
                      disabled={disableInput()}
                      style={{ height: 87 }}
                    />
                  ) : _form.type === 'timeTaken' ?
                    <span>{timeDiff}</span>
                  : _form.type === 'autocomplete' ? (
                    <AutoComplete
                    virtual={false}
                      disabled={disableInput() || _form.disabled}
                      options={_form.value}
                      onSelect={(e) => onSelect(e, _form.value)}
                      onSearch={(e) => onSearch(e, _form.value)}
                      placeholder="input here"
                    />
                  ) :
                  _form.type==="dateTime"?(
                    <>{_form.name==="equipment_dropoff_date"?
                    (<DatePicker  showTime={{ defaultValue:moment(dropOff)}}  value={moment(dropOff)} onChange={(date,dateString)=>setDropOff(dateString)} allowClear={false} disabledDate={d=>d.isAfter(moment(washStart).format('MM/DD/yyyy HH:mm'))}  format="MM/DD/yyyy hh:mm A" />
                   ): _form.name==="ticket_wash_start_time"?(
<DatePicker   value={moment(washStart)} showTime={{ defaultValue:moment(washStart)}}   onChange={(date,dateString)=>{setWashStart(dateString)}} allowClear={false} minTime={moment(dropOff)} maxTime={moment(washEnd)} disabledDate={d=>d.isBefore(moment(dropOff).format('MM/DD/yyyy HH:mm'))||d.isAfter(moment(washEnd).format('MM/DD/yyyy HH:mm'))} format="MM/DD/yyyy hh:mm A" />
 ):
 _form.name==="ticket_wash_end_time"?(
  <DatePicker showTime={{ defaultValue:moment(washEnd)}}  value={moment(washEnd)} onChange={(date,dateString)=>setWashEnd(dateString)} allowClear={false} minTime={ moment(washStart)} disabledDate={d=>d.isBefore(moment(washStart).format('MM/DD/yyyy HH:mm'))} format="MM/DD/yyyy hh:mm A" />
            ):(<DatePicker  value={moment(timeDiff)} disabled={true} format="HH:mm:ss" />
                    
                  )}</>):(
                    <Input disabled={_form.disabled || disableInput()} />
                  )}
                </Form.Item>
              </Col>
            ))}
          {/* {props.asset_txns_id && <Col span={12} />} */}
          <Col span={24}>
          <Collapse  defaultActiveKey={['1','2']}>
    <Panel header="Prewash Details" key="1">
    {prewashForm.map((_form) => (
              <Col span={_form.col}>
                <Form.Item
                  labelCol={{span: 24}}
                  wrapperCol={{span: 24}}
                  name={_form.name}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  // initialValue={_form.initialValue?_form.initialValue:''}
                  rules={_form.rules}
                >
                  {_form.type==="dropdown"?<Select
                    virtual={false}
                    mode={_form.type==="multiselect"&&"multiple"}
                      disabled={
                        (_form.name !== 'client_cust_id' && disableInput()) ||
                        _form.disabled
                      }
                     
                      allowClear={false}
                      placeholder={`SELECT ${_form.lable}`}
                      style={_form.style}
                      onChange={(e) => dropDownSelect(e, _form.name,_form.value)}
                    >
                      {_form.value &&
                        _form.value.map((item) => (
                          <Option value={item.value}>
                            {item.displayValue}
                          </Option>
                        ))}
                    </Select>
                    : _form.type === 'radio' ? (
                      <Radio.Group
                        disabled={disableInput()}
                        onChange={(e) => handleSource(e.target.value)}
                      >
                        {_form.value.map((item) => (
                          <Radio value={item.value}>{item.displayValue}</Radio>
                        ))}
                      </Radio.Group>
                    ) :<Input
                    disabled={disableInput()}
                    placeholder={_form.placeholder}
                  />}
                </Form.Item>
              </Col>))}
    </Panel>
    {props.postActive&&<Panel header="Postwash Details" key="2">
    {postWashForm().map((_form) => (
              <Col span={_form.col}>
                <Form.Item
                  labelCol={{span: 24}}
                  wrapperCol={{span: 24}}
                  name={_form.name}
                  label={<span>{_form.lable}</span>}
                  initialValue={_form.initialValue ? _form.initialValue : ''}
                  // initialValue={_form.initialValue?_form.initialValue:''}
                  rules={_form.rules}
                >
                  { _form.type==="dateTime"?(
                      <Space direction="vertical" size={12} style={{ color: "red" }}>
   {/* <DatePicker size="large" className="wash_taken_date_time" showTime={{ defaultValue: moment(props.time) }} onChange={onChange} allowClear={false} minTime={props.time} disabledDate={d=>d.isBefore(moment(parseInt(startTime)).format('MM/DD/yyyy HH:mm'))} format="MM/DD/yyyy hh:mm:ss A" /> */}
                      </Space> ):
                       _form.type === 'radio' ? (
                        <Radio.Group
                          disabled={disableInput()}
                        >
                          {_form.value.map((item) => (
                            <Radio value={item.value}>{item.displayValue}</Radio>
                          ))}
                        </Radio.Group>
                      ):
                  <Input
                    disabled={disableInput()}
                    placeholder={_form.placeholder}
                  />}
                </Form.Item>
              </Col>))}
    </Panel>}
  </Collapse>
  </Col>
        
        </Row>
      </Form>}
      {props.asset_txns_id &&
      loading && (
        <div className="eq-spinner">
          <Spin size="large" />
        </div>
      )}
      <style>
        {`
.drop-off{
justify-content: flex-start;
flex-direction: row;
display: flex;
}
.drop-off div{
display: flex;
justify-content: center;
align-items: center;
}
.pop-over-container{
  position: relative;
    display: flex;
    justify-content: center;
}
.pop-over{
  position: absolute;
  background: white;
  padding: 29px;
  width: 99%;
  max-width: 589px;
  bottom: 70px;
  border: 1px solid #c1baba;
  border-radius: 13px;
}
.eq-spinner{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    background: rgba(0,0,0,0.3);
}
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow{
  right:14px !important;
  left:unset !important;
}
`}
      </style>
    </Footer>
  );
};

export class Register extends Component {
  render() {
    return <EditTicket {...this.props} />;
  }
}
const mapStateToProps = (state) => ({
  yardLocationData: state.yardLocationData.yardLocationData,
  customerList: state.customer.customerList,
});

export default connect(mapStateToProps, {
  fetchYardList,
  CustomerList,
  getAssetList,
  getTicketDetails,
  getInYardList,
  getWashStatus,
  getDashboardCount,
  getTicketAwaiting
})(EditTicket);
